import React from 'react';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { setPendingPauseAudio } from '../../../Audio/actions/AudioActions';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import {
  StockItem,
  StockItemContext,
  StockItemFormat,
} from '../../../common/types/StockItemTypes';
import { stopRecommendedMusicDrawerTimer } from '../../../common/utils';
import { DrawerType } from '../SearchTypes';
import {
  collapseMoreLikeThisDrawer,
  updateDrawerNumberItemsVisible,
} from '../actions/SearchActions';
import {
  selectCollapsedSetStockItems,
  selectDrawerContentLoaded,
  selectDrawerType,
  selectSearchFeatures,
} from '../selectors/searchSelectors';
import SimpleSearchResults from './searchResults/SimpleSearchResults';

import './Drawer.less';

interface Props {
  stockItems: StockItemObj[];
  customStyles: object;
  // This is used to horizontally position the small triangle
  // that indicates which item was selected.
  indicatorClasses?: string;
  selectedItemIndicatorOffset?: number;
  drawerContentLoaded?: boolean;
  drawerType?: string;
  features: Features;
  dispatchUpdateDrawerNumberItemsVisible?: () => void;
  close: () => void;
  itemLimit?: number;
}

interface StockItemObj {
  stockItem: StockItem;
  stockItemFormats: StockItemFormat[];
}

interface Features {
  redirectToSignUpLink?: boolean;
  isEnterpriseMember?: boolean;
  shouldShowAddToFavorites?: boolean;

  useSubscriptionlessSignUpEnticement?: boolean;
}

const Drawer = ({
  customStyles = {},
  drawerType,
  indicatorClasses = null,
  selectedItemIndicatorOffset = null,
  close,
  drawerContentLoaded = false,
  features,
  dispatchUpdateDrawerNumberItemsVisible,
  itemLimit,
  stockItems = [],
}: Props) => {
  const isAltVersionsDrawer = () => {
    return drawerType === DrawerType.ALT_VERSIONS;
  };

  const getDrawerContentWrapperClasses = () => {
    return twMerge(
      !isAltVersionsDrawer() && 'bg-gray-600 rounded-lg px-8 py-4'
    );
  };

  const getDrawerContentClasses = () => {
    return twMerge(
      'content clear-both text-white relative',
      !isAltVersionsDrawer() && 'px-8 py-4 bg-white rounded-lg'
    );
  };

  const Indicator = () => {
    if (!indicatorClasses && !selectedItemIndicatorOffset) {
      return null;
    }

    const indicatorStyles = {
      marginLeft: undefined,
      width: undefined,
    };
    if (selectedItemIndicatorOffset) {
      indicatorStyles.marginLeft = `${selectedItemIndicatorOffset}px`;
      indicatorStyles.width = '0';
    }

    return (
      <div className={indicatorClasses} style={indicatorStyles}>
        <div className="shape w-0 h-0" />
      </div>
    );
  };

  const CloseButton = () => {
    const className = twMerge(
      'collapse-button text-4xl text-black absolute right-4 top-4 border-0 outline-none leading-4',
      isAltVersionsDrawer() && 'hidden'
    );

    return (
      <button
        aria-hidden="true"
        className={className}
        onClick={close}
        title="Close"
      >
        &times;
      </button>
    );
  };

  const DrawerTitle = () => {
    const className = twMerge(
      'title m-0 text-left text-black text-base font-normal inline-block border-solid border-0 border-b border-gray-500',
      isAltVersionsDrawer() && 'hidden'
    );

    let titleText;
    switch (drawerType) {
      case DrawerType.MORE_LIKE_THIS:
        titleText = 'More Like This';
        break;
      case DrawerType.RECOMMENDED_MUSIC:
        titleText = 'Recommended Music';
        break;
      default:
        titleText = '';
    }

    return <h4 className={className}>{titleText}</h4>;
  };

  const StockItems = () => {
    const currentStockItems =
      stockItems.length && itemLimit
        ? stockItems.slice(0, itemLimit)
        : stockItems;

    if (drawerContentLoaded) {
      return (
        <SimpleSearchResults
          currentStockItems={currentStockItems}
          redirectToSignUpLink={features.redirectToSignUpLink}
          shouldShowAddToFavorites={features.shouldShowAddToFavorites}
          context={StockItemContext.DRAWER}
          containsCollapsedStockItems={true}
          updateDrawerNumberItemsVisible={
            dispatchUpdateDrawerNumberItemsVisible
          }
          useSubscriptionlessSignUpEnticement={
            features.useSubscriptionlessSignUpEnticement
          }
        />
      );
    }
    return <LoadingIndicator />;
  };

  return (
    <div
      className="searchDrawer w-full bg-transparent mb-2.5 px-2 py-0"
      style={customStyles}
    >
      <Indicator />
      <div className={getDrawerContentWrapperClasses()}>
        <div className={getDrawerContentClasses()}>
          <CloseButton />
          <DrawerTitle />
          <StockItems />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    stockItems: selectCollapsedSetStockItems(state),
    drawerContentLoaded: selectDrawerContentLoaded(state),
    drawerType: selectDrawerType(state),
    features: selectSearchFeatures(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(setPendingPauseAudio());
      stopRecommendedMusicDrawerTimer();
      dispatch(collapseMoreLikeThisDrawer());
    },
    dispatchUpdateDrawerNumberItemsVisible: (numItems) =>
      dispatch(updateDrawerNumberItemsVisible(numItems)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
